import React, { useRef, useState } from "react";

import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerActions
} from "Organisms";
import { IDrawerComponentContext, useLanguage } from "Providers";
import { ImageType } from "./utils/types";
import { Button, Flex, ImageCrop, ImageFileRef } from "Atoms";
import { useMothershipMutation } from "Hooks";
import { UPLOAD_FILE } from "GraphQLMutations";
import { showToastError, showToastSuccess, convertFileToBase64String } from "Utils";
import { useQoplaStore } from "Stores";
import { isImageUrlValid } from "./utils/functions";

type Props = {
    image: ImageType;
    addCroppedImage: (croppedImage: ImageType) => void;
};

/**
 * [FUNCTION] - Check path and if image has multiple sizes / will use original to crop
 * @param path
 * @param hasMultipleSizes
 * @returns
 */
const getDisplayUrl = (path: string, hasMultipleSizes: boolean) => {
    const url = path.split("?");

    if (!!url.length) {
        const imageUrl = url[0];
        if (hasMultipleSizes) {
            return imageUrl.replace("/medium/", "/original/");
        }
        return imageUrl;
    }

    return path;
};

export const ImageCropDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { image, addCroppedImage },
    onCloseDrawer
}) => {
    /** Ref to forward to image crop to capture function */
    const cropRef = useRef<ImageFileRef>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);

    const [uploadCroppedImage] = useMothershipMutation(UPLOAD_FILE);

    const { translate } = useLanguage();
    const {
        selectedCompany: { id: companyId }
    } = useQoplaStore();

    const imageUrl = getDisplayUrl(image.path, (image?.totalResizedImages ?? 0) === 4);

    /** If there is a small image crop is freeform and this is to disable to save to prevent errors */
    const onHandleSaveButton = (isDisabled: boolean) => setIsSaveDisabled(isDisabled);

    const onHandleClick = async () => {
        setIsSubmitting(true);
        try {
            if (!!cropRef.current?.getImageFile) {
                /** Step 1: Get cropped image file */
                const base64ImageFile = await cropRef.current.getImageFile();
                if (!!base64ImageFile) {
                    const { file, ...removeFile } = base64ImageFile;
                    /** Step 2: Create base 64 string */
                    const base64String = await convertFileToBase64String(base64ImageFile.file);

                    if (!!base64String) {
                        /** Step 3: Upload cropped image */
                        const { data } = await uploadCroppedImage({
                            variables: {
                                base64Imgs: [
                                    {
                                        ...removeFile,
                                        ...base64String
                                    }
                                ]
                            }
                        });
                        if (!!data?.uploadFile) {
                            const [imageUrl] = data.uploadFile;
                            /** Step 4: Check that url points to a valid image */
                            const isValid = await isImageUrlValid(imageUrl);
                            if (isValid) {
                                showToastSuccess(translate("imageHasbeenSaved"));
                                const [name, extension] = file.name.split(".");
                                /** Final step: Add to media library + close drawer */
                                addCroppedImage({
                                    path: imageUrl,
                                    fileExtension: extension,
                                    hideImage: false,
                                    subFolder: "cropped",
                                    title: name
                                });
                                onCloseDrawer();
                            } else {
                                showToastError(translate("somethingWentWrong"));
                            }
                        } else {
                            showToastError(translate("somethingWentWrong"));
                        }
                    }
                }
            }
        } catch (error) {
            showToastError(translate("somethingWentWrong"));
        }

        setIsSubmitting(false);
    };

    return (
        <Drawer size="lg" open onClose={onCloseDrawer}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader display="flex" justifyContent="center" alignItems="center">
                    {translate("imageCrop")}
                </DrawerHeader>
                <DrawerCloseButton top="15px" />
                <DrawerBody pt="0" maxHeight="80%">
                    <Flex direction="column" justifyContent="flex-start" alignItems="center">
                        <ImageCrop
                            imageUrl={imageUrl}
                            companyId={companyId}
                            ref={cropRef}
                            disableSaveImage={onHandleSaveButton}
                        />
                    </Flex>
                </DrawerBody>
                <DrawerActions position={"absolute"} bottom={0} width="100%">
                    <Flex justifyContent="space-between" width="100%">
                        <Button
                            size="xl"
                            isDisabled={isSubmitting}
                            themeColor="red"
                            variant="outline"
                            onClick={onCloseDrawer}
                        >
                            {translate("cancel")}
                        </Button>
                        <Button
                            size="xl"
                            isLoading={isSubmitting}
                            isDisabled={isSaveDisabled || isSubmitting}
                            themeColor="green"
                            onClick={onHandleClick}
                        >
                            {translate("save")}
                        </Button>
                    </Flex>
                </DrawerActions>
            </DrawerContent>
        </Drawer>
    );
};
